<template>
  <div class="v_change_qq">
    <head-back>
      <template v-slot:returnText>
        <span>返回</span>
      </template>

        <template v-slot:default>
        <span>修改昵称</span>
      </template>
    </head-back>

    <div class="v-change-info-content">
      <div class="v-change-info-form">
        <div class="v-change-info-form-item">
           <van-field v-model="userInfo.nick_name" label="昵称:" placeholder="请输入昵称" clearable/>
        </div>
      </div>
    </div>

    <div class="v-change-info-btn">
      <operation-button @clickItem="emitClickItem" :buttonObj="buttonObj"></operation-button>
    </div>
  </div>
</template>

<script>
  import HeadBack from '../../components/HeadBack.vue'
  import OperationButton from '../../components/OperationButton.vue'
  import { apiGetUserInfo, apiEditInfo } from '@/utils/api.js'
  export default {
    components: { HeadBack, OperationButton },
    data() {
      return {
        userInfo: this.$global.userInfo,
        buttonObj: {
          claSS: 'c-btn-default',
          title: '确认提交',
          event: 'apiEditInfoHandel'
        }
      }
    },
    methods: {
      // 修改昵称
      async apiEditInfoHandel() {
        this.$global.loadingShow = true
        const { success, data } = await apiEditInfo({ nick_name: this.userInfo.nick_name })
        if(!success) return
        this.apiGetUserInfoHandel()
        console.log(data)
      },
      // 获取用户信息
      async apiGetUserInfoHandel() {
        const { success, data } = await apiGetUserInfo()
        if(!success) return
        this.$toast('修改成功')
        this.$global.setUserInfo(data.data)
        this.userInfo = data.data
        console.log(data)
      },      
      emitClickItem(event) {
        if(!event) return
        this[event]()
      }
    }
  }
</script>

<style lang="scss">
.v_change_qq {
  height: 100%;
  background: #F1F1F1;
  .v-change-info-content {
    padding: 10px;
    .v-change-info-form {
      background: $white;
      border-radius: 8px;
      overflow: hidden;
      .v-change-info-form-item {
        .v-change-info-form-item-box {
          padding: 10px 16px;
          .v-change-info-form-item-label {
            flex: 1;
            color: #646566;
            text-align: left;
            font-size: 14px;
          }
          .van-radio-group {
            flex: 2;
            font-size: 14px;
          }
        }

        .van-cell {
          .van-cell__title  {
            margin-right: 0;
            flex: 1;
            color: #646566;
          }
          .van-cell__value {
            flex: 2;
            text-align: left;
            .van-field__control {
              color: #969799;
            }
          }
        }
      }
    }
  }
  .v-change-info-btn {
    margin-top: 20px;
  }
}
</style>